// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-article-js": () => import("./../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-serviceone-js": () => import("./../src/pages/serviceone.js" /* webpackChunkName: "component---src-pages-serviceone-js" */),
  "component---src-pages-servicethree-js": () => import("./../src/pages/servicethree.js" /* webpackChunkName: "component---src-pages-servicethree-js" */),
  "component---src-pages-servicetwo-js": () => import("./../src/pages/servicetwo.js" /* webpackChunkName: "component---src-pages-servicetwo-js" */)
}

